if(jQuery('#faq-group').length) {
	jQuery('#faq-group .answer').each(function() {
		jQuery(this).on('hide.bs.collapse', function() {
			let q = jQuery(this).prev();
			q.removeClass('open');
		});
		jQuery(this).on('show.bs.collapse', function() {
			let q = jQuery(this).prev();
			q.addClass('open');
		});
	});
}
