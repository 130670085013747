require('../scss/theme.scss');

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import './components/faqs.js';

//IMAGES
import '../image/contechLogo.svg';


function menuCollapse(parentUL) {
	var parentLI = jQuery(parentUL).find('.menu-item-has-children');
	jQuery(parentLI).each(function(i) {
		var trigger = jQuery(this).find('> a');
		var tID = jQuery(this).attr('id');
		var target = jQuery(trigger).find('+ .sub-menu');
		var subId = 'sub-menu-' + tID;
		if (jQuery(document).width() > 991) {
			jQuery(trigger).click(function (event) {
				event.preventDefault();
			});
		} else {
			jQuery(target).attr('id', subId).addClass('collapse');
			jQuery(trigger).attr({ 'data-toggle': 'collapse', href: '#' + subId });
		}
	});
}


jQuery(document).ready(function (jQuery) {
	//SVG INJECTOR
	var elementsToInject = document.querySelectorAll('.inject-me');
	var injectorOptions = {
		evalScripts: 'once'
	};
	var injector = new SVGInjector(injectorOptions);
	if (jQuery(elementsToInject).length) {
		injector.inject(
			elementsToInject
		);
	}

	//Add collapse tags to footer menus
	if (jQuery('#menu-footer-menu-1').length) {
		menuCollapse(jQuery('#menu-footer-menu-1'));
	}
	if (jQuery('#menu-footer-menu-2').length) {
		menuCollapse(jQuery('#menu-footer-menu-2'));
	}
	if (jQuery('#menu-footer-menu-3').length) {
		menuCollapse(jQuery('#menu-footer-menu-3'));
	}
	if (jQuery('#menu-footer-menu-4').length) {
		menuCollapse(jQuery('#menu-footer-menu-4'));
	}
});

